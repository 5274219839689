import TitleBar from "./Components/Common/TitleBar";
import { Route, Routes } from "react-router-dom";
import Login from "./Components/UserAuthentication/Login";
import Signup from "./Components/UserAuthentication/Signup";
import ProtectedRoutes from "./Components/Common/ProtectedRoutes";
import WaitlistForm from "./Components/Waitlist/WaitlistForm";
import React, { useState, useEffect } from "react";
import SearchComponent from "./Components/Search/SearchComponent";
import BookmarkComponent from "./Components/BookMark/BookmarkComponent";
import MyBooksComponent from "./Components/UserBooks/MyBooks";
import UserProfileComponent from "./Components/User/UserProfile";
import HomeComponent from "./Components/Home/HomeComponent";
import NavBar from "./Components/Common/NavBar";
import OrderComponent from "./Components/Order/OrderComponent";
import SsoVerification from "./Components/UserAuthentication/SsoVerification";
import ForgotPassword from "./Components/UserAuthentication/ForgotPassword";
import SubscriptionPlans from "./Components/User/SubscriptionPlans";
import ChatCommponent from "./Components/Chat/ChatComponent";
import { getRole, verifyLogin } from "./Components/UserAuthentication/AuthUtils";
import ConfirmEmail from "./Components/UserAuthentication/ConfirmEmail";
import LandingPageComponent from "./Components/LandingPage/LandingPageComponent.jsx";
import ForgotPasswordVerify from "./Components/UserAuthentication/ForgotPasswordVerify.jsx";
import RequestBookComponent from "./Components/RequestBook/RequestBookComponent.jsx";
import DeliveryComponent from "./Components/Delivery/DeliveryComponent.jsx";

export const UserContext = React.createContext(null);

function App() {
  const [selectedTab, setSelectedTab] = useState("home");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const verifyUserLogin = async () => {
      setIsUserLoggedIn(await verifyLogin());
    };

    verifyUserLogin();
  }, []);
  const userRole = getRole();

  const tabs = [
    {
      id: "home",
      label: "Home",
      icon: "fa-solid fa-house",
      path: "/home",
      component: <HomeComponent setSelectedTab={setSelectedTab} />,
      acl : ["ADMIN","GOD","BASIC_USER"]

    },
    {
      id: "search",
      label: "Search",
      icon: "fa-solid fa-magnifying-glass",
      path: "/search",
      component: <SearchComponent setSelectedTab={setSelectedTab} />,
      acl : ["ADMIN","GOD","BASIC_USER"]

    },
    {
      id: "chat",
      label: "Chat",
      icon: "fa-solid fa-comment",
      path: "/chat",
      component: <ChatCommponent />,
      acl : ["ADMIN","GOD","BASIC_USER"]

    },
    {
      id: "bookmarks",
      label: "Bookmarks",
      icon: "fa-solid fa-bookmark",
      path: "/bookmarks",
      component: <BookmarkComponent setSelectedTab={setSelectedTab} />,
      acl : ["ADMIN","GOD","BASIC_USER"]

    },
    {
      id: "books",
      label: "Books",
      icon: "fa-solid fa-book",
      path: "/books",
      component: <MyBooksComponent setSelectedTab={setSelectedTab} />,
      acl : ["ADMIN","GOD","BASIC_USER"]
    },
    {
      id: "delivery",
      label: "Delivery",
      icon: "fa-solid fa-truck",
      path: "/delivery",
      component: <DeliveryComponent setSelectedTab={setSelectedTab} />,
      acl : ["ADMIN","GOD"]
    }
  ];

  return (
    <UserContext.Provider value={{ isUserLoggedIn, setIsUserLoggedIn }}>
      <div className="App h-dvh w-dvw flex flex-col">
        <div className=" ">
          <TitleBar setSelectedTab={setSelectedTab} />
        </div>
        <div className="flex-grow w-full overflow-y-auto">
          <Routes>
            <Route path="/waitlist" element={<WaitlistForm />} />
            <Route path="/*" element={<LandingPageComponent />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            {tabs
            .filter((tab) => tab.acl.includes(userRole)).map((tab) => ( ( <Route
                key={tab.id}
                path={tab.path}
                element={
                  <ProtectedRoutes>
                    <div className="flex flex-col md:flex-row h-[100%] w-full">
                      <div className="md:w-16 lg:w-20 md:h-full order-last md:order-none">
                        <NavBar
                          tabs={tabs}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                        />
                      </div>
                      <div className="w-full flex-grow overflow-y-auto bg-customYellow">
                        {tab.component}
                      </div>
                    </div>
                  </ProtectedRoutes>
                }
              />)
              
            ))}
            <Route
              path="/profile"
              element={
                <ProtectedRoutes>
                  <div className="flex flex-col md:flex-row h-[100%] w-full">
                    <div className="md:w-16 lg:w-20 md:h-full order-last md:order-none">
                      <NavBar
                        tabs={tabs}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                      />
                    </div>
                    <div className="w-full  flex-grow overflow-y-auto bg-gray-800">
                      <UserProfileComponent setSelectedTab={setSelectedTab} />
                    </div>
                  </div>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/order"
              element={
                <ProtectedRoutes>
                  <OrderComponent />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/request-book"
              element={
                <ProtectedRoutes>
                   <div className="flex flex-col md:flex-row h-[100%] w-full">
                      <div className="md:w-16 lg:w-20 md:h-full order-last md:order-none">
                        <NavBar
                          tabs={tabs}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                        />
                      </div>
                      <div className="w-full flex-grow overflow-y-auto bg-customBlue">
                      <RequestBookComponent />
                      </div>
                    </div>
                  
                </ProtectedRoutes>
              }
            />
            RequestBookComponent
            <Route path="/sso_access" element={<SsoVerification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route path="/confirm_email" element={<ConfirmEmail />} />
            <Route path="/reset_password" element={<ForgotPasswordVerify />} />
            <Route
              path="/subscription"
              element={
                <ProtectedRoutes>
                  <SubscriptionPlans />
                </ProtectedRoutes>
              }
            />
          </Routes>
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default App;
